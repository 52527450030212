import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  typography: {
    fontFamily: ['"Nunito"'],
  },
  palette: {
    type: 'light',
    default: {
      main: '#fff',
      grey: '#f9f9f9',
      background: '#e5e5e5',
    },
    flora: {
      main: '#57B898',
      hover: '#56937E',
    },
    primary: {
      main: '#232C34',
      text: '#232C34',
      green: '#5abc9c',
    },
    secondary: {
      main: '#FFFFFF',
      text: '#5D8EA8',
      hover: '#3b657c',
      sub: '#626262',
      nav: '#7DA7BE',
    },
    accent: {
      main: '#ec6b53',
    },
    info: {
      main: '#4b91c2',
    },
    error: {
      main: '#f37151',
    },
    warning: {
      main: '#fbd368',
    },
    divider: '#aaaaaa',
  },
});

theme.components = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        marginBottom: theme.spacing(2),
        marginLeft: '10%',
        marginRight: '10%',
        borderColor: theme.palette.secondary.main,
        '&:hover': {
          borderColor: theme.palette.secondary.main,
        },
      },
      input: {
        paddingTop: '18px',
        paddingBottom: '18px',
        borderColor: theme.palette.secondary.main,
        '&:hover': {
          borderColor: theme.palette.secondary.main,
        },
      },
      notchedOutline: {
        borderColor: theme.palette.secondary.main,
        '&:hover': {
          borderColor: theme.palette.secondary.main,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: theme.palette.secondary.main,
        '&:hover': {
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        background: '#222d36',
        color: theme.palette.secondary.main,
      },
      input: {
        color: theme.palette.secondary.main,
        '&:hover': {
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      size: 'small',
    },
    styleOverrides: {
      root: {
        width: '80%',
        paddingTop: '16px',
        paddingBottom: '16px',
        marginLeft: '10%',
        borderRadius: theme.spacing(1),
        textTransform: 'none',
        fontSize: '1rem',
      },
      // containedPrimary: {
      //   color: theme.palette.default.main,
      //   '&:hover': {
      //     backgroundColor: theme.palette.default.main,
      //     color: theme.palette.primary.dark,
      //   },
      // },
      // containedSecondary: {
      //   color: theme.palette.default.main,
      //   transition: '0.3s',
      //   fontWeight: 700,
      //   '&:hover': {
      //     backgroundColor: theme.palette.secondary.hover,
      //     color: theme.palette.default.main,
      //   },
      // },
      // containedFlora: {
      //   color: theme.palette.default.main,
      //   transition: '0.3s',
      //   fontWeight: 700,
      //   '&:hover': {
      //     backgroundColor: theme.palette.flora.hover,
      //     color: theme.palette.default.main,
      //   },
      // },
      // outlinedSecondary: {
      //   color: theme.palette.secondary.main,
      //   transition: '0.3s',
      //   fontWeight: 700,
      //   '&:hover': {
      //     color: theme.palette.secondary.hover,
      //   },
      // },
      contained: {
        backgroundColor: '#5BBC9C',
        '&:hover': {
          backgroundColor: '#5BBC9C',
        },
      },
      outlined: {
        '&:hover': {
          border: '2px solid',
        },
      },
    },
  },
};

theme = responsiveFontSizes(theme);

export default theme;
