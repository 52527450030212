import './App.css';
import { Box, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import { makeStyles } from '@mui/styles';
import logo from '../src/assets/iO_lockup.png';
import bgImage from '../src/assets/GradientBackground.png';
import checkmark from '../src/assets/CheckmarkforQR.svg';
import { LoadingButton } from '@mui/lab';
import wordmark from '../src/assets/Wordmark.png';
import spms from '../src/assets/SPMS.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#222D36',
    minHeight: '100vh',
  },
  success: {
    minHeight: '100vh',
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
  },
  input: {
    marginBottom: '8px',
    color: '#fff',
    width: '100%',
  },
  button: {
    backgroundColor: '#5BBC9C',
  },
}));

// const SPREADSHEET_ID = '1HGVa41c8-cff1S1EbeWZzK1_pRXaOxUaVUozTHWB1Rs';
const SPREADSHEET_ID = '1WNfGuUL_6J7UAMnHFaexCKrglSFD0gwo2ezkghsBkSc';
const SHEET_ID = 0;
const CLIENT_EMAIL =
  'inorbit-service-account@inorbit-contact-collection.iam.gserviceaccount.com';
const PRIVATE_KEY =
  '-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCv7QUdOXX9lfxb\ns32yLtY4iybWpCdcFjPSW7VLr99Sl3F4JQ8B4McR4Y4N5Z6ejd2hvPrZguKsH+Sp\nKDlUOAkMj2/zzOC3aQZcD+0H2j5oKcwyoga7WDct8OWBeajQJVi4v75FqnEK/kzQ\n4kWPPNQSiqpaOXVrpEWdPbhZXRo5snzhN3blMoxTf9bJFyaI22L3877qHZnC7hBE\ndwHelIhkt82RQodsdAeEXBY4FRfFfQSsKGXQrxkdcW1WsnZkL3SDZ+3TEqbjq3Tf\ngqvkJRbK9mu8M/B4xutxM9kFD5fXPHo6rXwC1FUMsf2wMT034/tEiJRnVGHwTSWy\nmP3Lf1qvAgMBAAECggEACG5yIF9dRcqt6+ycgqgEteIJZ42nfYuzt/gBXW2ddywV\n7E/y8NKZ9Y0SMi+YSgvEHLRduRX6R9ZGCktkJtmCFzs6oyDTEbTzp7gFrhhzPDxX\ncoM1kmKT4dyQr97OTMLsT2lHa7fBQjKM38f5i+WoWb22lIbVu+Xgqs7rqmed9sqS\nj+Hympycgw+TKDA2SsqzW4gp+HJWRMlbQsVhLTBzercA/L2ndUCeTWM4Ag3CvnIy\nfaK4IamC7jov4CJvlcF4DCqzmeYOxf2MHiNupI+5/hA0KAFXxF55JSfx5808EgSp\nDLkNxeMsDcK2HZjtKIaUupyVKKGeYdUvSrKWJKAhPQKBgQDXfsy+eZPR9VzZBf6+\ns1skPjg28z9GxO4gvCIZb1OOe8o56QjTs4MQmsLcLdAP7j21DoyQGf8HaBpbHBcq\nMU34N0TRHkDiIQEdnujvOuiuW2JA1iaZGV2EgR2AJTrsjHKJMov3uL4YHXVn104x\nvUZ+OwlhZo6Cf8vZ8XRKCPFDNQKBgQDQ/jfGexyUE13cTOylIwGt91MwB4RTWs5H\nL6YqnFU44Ec9Fc11nKcoXKtsIa4orRXLnvopD8uoH379COp2IP3yWH/uB+3xiZSK\ni1kIyuzrTX/sxm8qUvxpcTrQwNYARmReosQIB2K8Hz6ZHf5W9MJmoAch830GK3Qf\nbnAT6Yne0wKBgQC/llI9viV382b64+OadCdBz7d200iciJUVsW23o0xsTw558f8R\nCHYsGwvHaUuj/jIKeMbrjtE4KovUGONXdcJWPf0G1pjk8mbliNSbbIpWCZUPA9YJ\nI44LMieiOHdsu2lRJQlf0nXfRsGM8tToT9kOfTpXyDBHQYthVtQGxnCJSQKBgGT8\nso7iJmkXAp/VO/R/+evKOxRWTYbJtiuHxs+IQ1X8afAGfQV78Pn77YmOrM1S9dky\ntMbqrwpKTd6PVFxIjO1x/j9S7ZNtW62msBsjk2AQTuRm7oiRGQ8Ke9UrmT0AxrdE\nB12p0/f7URBEAgaatuVIJqotMhitHquhfd7hVpGpAoGBANQk1mrcVf5vMY35WsNh\nTEvowf5G3mG4S7elIypA7Vj2+HXe99ejHkJW33fogwMxAQRZ6LoYezY7VIIRI/su\noVr1w7Twj4ZM/p3n9JeFZcYuVl0ykFyrExuiYU3tISJHj1ai6Te1EOxaokVasUTd\n18aL2ocGwPTUoS0fG8hMwKhj\n-----END PRIVATE KEY-----\n';

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

function App() {
  const classes = useStyles();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [title, setTitle] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [isSuccess, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function handleSubmit() {
    setError('');

    if (!email) {
      setError('Please enter an email address');
      return;
    }
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setIsLoading(true);
    const newRow = { firstName, lastName, title, company, email };

    appendSpreadsheet(newRow);
  }

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      // loads document properties and worksheets
      await doc.loadInfo();

      const sheet = doc.sheetsById[SHEET_ID];
      await sheet.addRow(row);
      setSuccess(true);
    } catch (e) {
      console.error('Error: ', e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {!isSuccess && (
        <>
          <Grid
            container
            spacing={0}
            direction='column'
            alignItems='flex-start'
            justifyContent='center'
            className={classes.root}
          >
            <Grid
              item
              xs={12}
              style={{
                paddingLeft: '10%',
                paddingTop: '20%',
                maxHeight: `calc(100vh - 33%)`,
              }}
            >
              <Box>
                <img src={logo} alt='inOrbit logo' width='80%' height='80%' />
              </Box>
            </Grid>
            <Grid item xs={12} style={{ maxHeight: `calc(100vh - 33%)` }}>
              <Box
                style={{ marginTop: '60px', paddingLeft: '10%' }}
                width='100%'
              >
                <Typography
                  fontSize={42}
                  fontWeight={400}
                  lineHeight={1.2}
                  sx={{ color: '#fff' }}
                >
                  Join Us for the Launch
                </Typography>
              </Box>
              <Box
                marginTop={2}
                marginBottom={6}
                style={{ paddingLeft: '10%', paddingRight: '10%' }}
              >
                <Typography
                  sx={{ color: '#fff' }}
                  fontSize={18}
                  fontWeight={400}
                >
                  Sign up to receive updates, schedule demos, and learn more
                  about this revolutionary security program management software.
                </Typography>
              </Box>
              <Grid item xs={12} sx={{ paddingBottom: '32px' }}>
                <TextField
                  color='secondary'
                  placeholder='First Name'
                  className={classes.input}
                  size='small'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                  color='secondary'
                  className={classes.input}
                  size='small'
                  placeholder='Last Name'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <TextField
                  color='secondary'
                  className={classes.input}
                  size='small'
                  placeholder='Job Title'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                  color='secondary'
                  className={classes.input}
                  size='small'
                  placeholder='Company Name'
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
                <TextField
                  color='secondary'
                  className={classes.input}
                  type='email'
                  size='small'
                  placeholder='Email Address'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <LoadingButton
                  fullWidth
                  variant='contained'
                  sx={{ backgroundColor: '#5BBC9C', color: '#fff', mb: 1 }}
                  onClick={handleSubmit}
                  loading={isLoading}
                  loadingPosition='start'
                >
                  <Typography fontSize='21px' fontWeight={700}>
                    Submit
                  </Typography>
                </LoadingButton>
                {error && (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography color='error' fontSize={16} fontWeight={600}>
                      {error}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {isSuccess && (
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='flex-start'
          justifyContent='center'
          className={classes.success}
          style={{ paddingLeft: '10%', paddingRight: '10%' }}
        >
          <Grid
            item
            xs={12}
            style={{
              maxHeight: `calc(100vh - 33%)`,
            }}
          >
            <Box sx={{ mb: 4 }}>
              <img src={checkmark} alt='green checkmark' />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ maxHeight: `calc(100vh - 33%)`, marginBottom: 82 }}
          >
            <Box sx={{ mb: 3 }}>
              <Typography color='secondary' fontWeight={400} fontSize={44}>
                Success!
              </Typography>
              <Typography color='secondary' fontWeight={400} fontSize={18}>
                Thank you for joining a community of security professionals
                seeking to leverage technology that simplifies the day-to-day
                tasks associated with maintaining an effective security program.
                <br />
                <br />
                We will be reaching out soon with more information.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ maxHeight: `calc(100vh - 33%)` }}>
            <Box>
              <img src={wordmark} alt='inOrbit wordmark' />
            </Box>
            <Box>
              <img src={spms} alt='security program management software' />
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default App;
